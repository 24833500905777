/* global fetch */
const sendEvent = async (event, data) => {
    try {
        const response = await fetch('/event', {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({ event, data })
        });

        if (response.status === 200) {
            const json = await response.json();

            if (json.refresh) {
                window.location.reload();
            }
        }
    } catch (err) {
        console.error(err);
    }
};

export default sendEvent;
